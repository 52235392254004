<script>
  import SectHeading from '../common/SectHeading.svelte';
  import GalleryNav from './GalleryNav.svelte';
  import GalleryNavMobile from './GalleryNavMobile.svelte';

  import haircut1 from 'url:./haircuts/img-1.png';
  import haircut2 from 'url:./haircuts/img-2.png';
  import haircut3 from 'url:./haircuts/img-3.png';
  import haircut4 from 'url:./haircuts/haircut_5.jpg';

  import design1 from 'url:./hair-designs/img-1.png';
  import design2 from 'url:./hair-designs/img-2.png';
  import design3 from 'url:./hair-designs/img-3.png';
  import design4 from 'url:./hair-designs/hair_design_4.jpg';
  import design5 from 'url:./hair-designs/hair_design_2.jpg';

  import braids1 from 'url:./braids/braids_1.jpg';
  import braids2 from 'url:./braids/braids_2.jpg';
  import braids3 from 'url:./braids/braids_3.jpg';
  import braids4 from 'url:./braids/braids_4.jpg';
  import braids5 from 'url:./braids/braids_5.jpg';

  import beard1 from 'url:./beards/beard_1.jpg';
  import beard2 from 'url:./beards/beard_2.jpg';
  import beard3 from 'url:./beards/beard_3.jpg';

  const sections = [
    { key: 'allStyles', label: 'All Styles' },
    { key: 'cuts', label: 'Haircuts' },
    { key: 'beards', label: 'Beards' },
    { key: 'braids', label: 'Braids' },
    { key: 'designs', label: 'Hair Designs' }
    // { key: 'lashes', label: 'Lashes' },
    // { key: 'nails', label: 'Nails' },
    // { key: 'brows', label: 'Eyebrows' }
  ];
  const images = {
    cuts: [
      { src: haircut1, alt: '' },
      { src: haircut2, alt: '' },
      { src: haircut3, alt: '' },
      { src: haircut4, alt: '' }
    ],
    designs: [
      { src: design1, alt: '' },
      { src: design2, alt: '' },
      { src: design3, alt: '' },
      { src: design4, alt: '' },
      { src: design5, alt: '' }
    ],
    beards: [
      { src: beard1, alt: '' },
      { src: beard2, alt: '' },
      { src: beard3, alt: '' }
    ],
    braids: [
      { src: braids1, alt: '' },
      { src: braids2, alt: '' },
      { src: braids3, alt: '' },
      { src: braids4, alt: '' },
      { src: braids5, alt: '' }
    ],
    lashes: [],
    nails: [],
    brows: []
  };
  images.allStyles = images.cuts.concat(
    images.designs,
    images.beards,
    images.braids,
    images.lashes,
    images.nails,
    images.brows
  );

  let activeSection = sections[0];
  let isOpen = false;
</script>

<div class="offset-header" id="Gallery" />
<section
  class="container relative px-8 pt-8 pb-16 gallery-group lg:py-16 gallery"
>
  <SectHeading text="Gallery" center />
  <GalleryNav
    {sections}
    {activeSection}
    setActiveSection={sect => {
      activeSection = sect;
    }}
    setOpen={open => {
      isOpen = open;
    }}
  />
  <GalleryNavMobile
    {sections}
    {activeSection}
    {isOpen}
    setActiveSection={sect => {
      activeSection = sect;
    }}
    setOpen={open => {
      isOpen = open;
    }}
  />
  <div class="flex flex-wrap items-start gallery-sect">
    {#each images[activeSection.key] as img}
      <img
        alt={img.alt}
        class="w-1/2 h-auto gallery-img lg:w-1/4"
        src={img.src}
        height={300}
        width={300}
      />
    {/each}
  </div>

  {#if isOpen}
    <div class="absolute inset-0 z-0 opacity-75 bg-darkSlateGray lg:hidden" />
  {/if}
</section>

<style>
  @media only screen and (min-width: 768px) {
    .gallery-group {
      min-height: 1024px;
    }
  }

  @media only screen and (min-width: 1280px) {
    .gallery-group {
      min-height: 100vh;
    }
  }
</style>
