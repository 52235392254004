<script>
  import AngleDown from '../common/AngleDown.svelte';
  import AngleUp from '../common/AngleUp.svelte';
  import NavLink from './NavLink.svelte';

  export let sections;
  export let isOpen = false;
  export let setOpen = () => {};
  export let activeSection = sections[0];
  export let setActiveSection = () => {};
</script>

<div class="relative mb-8 lg:hidden">
  <div class="relative z-10 dia left" />
  <div
    class="relative flex items-center bg-white cursor-pointer justify-self-end"
  >
    <button
      class="relative z-10 flex items-center justify-between w-full px-4 py-2 text-lg font-bold border-b-2 appearance-none trigger text-sandyBrown-500 border-sandyBrown-500"
      on:click={() => {
        setOpen(!isOpen);
      }}
      title="Open Menu"
    >
      <span>{activeSection.label}</span>
      {#if isOpen}
        <AngleUp className="w-5 h-3 ml-2" />
      {:else}
        <AngleDown className="w-5 h-3 ml-2" />
      {/if}
    </button>
    {#if isOpen}
      <nav
        class="absolute left-0 right-0 z-10 flex flex-col px-4 py-2 bg-white shadow nav top-full"
        role="navigation"
      >
        {#each sections as sect}
          <NavLink
            active={activeSection === sect}
            content={sect.label}
            handleClick={() => {
              setActiveSection(sect);
              setOpen(false);
            }}
          />
        {/each}
      </nav>
    {/if}
  </div>
  <div class="relative z-10 dia right" />
</div>

<style>
  .nav {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .dia {
    position: absolute;
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-bottom-color: #d4a354;
    bottom: 1px;
  }

  .dia.left {
    left: -12px;
  }

  .dia.right {
    right: -12px;
  }

  .dia::after {
    content: '';
    position: absolute;
    left: -4px;
    top: 4px;
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-top-color: #d4a354;
  }
</style>
