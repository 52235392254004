<script>
  export let content;
  export let active = false;
  export let handleClick = () => {};
</script>

{#if active}
  <div
    class="relative items-center hidden px-4 py-1 font-normal border-b text-sandyBrown-500 border-sandyBrown-500 lg:flex"
  >
    <div class="relative z-10 dia left" />
    {content}
    <div class="relative z-10 dia right" />
  </div>
{:else}
  <button
    class="items-center hidden px-4 py-1 font-normal appearance-none lg:flex hover:text-sandbyBrown-500"
    on:click={handleClick}
  >
    {content}
  </button>
  <!-- Mobile NavLink -->
  <button
    class="w-full px-6 py-3 text-xl font-normal text-center border-b appearance-none lg:hidden border-transparentBlack hover:text-sandbyBrown-500 last:border-0"
    on:click={handleClick}
  >
    {content}
  </button>
{/if}

<style>
  .dia {
    position: absolute;
    width: 0;
    height: 0;
    border: 3px solid transparent;
    border-bottom-color: #d4a354;
    bottom: 0;
  }

  .dia.left {
    left: -9px;
  }

  .dia.right {
    right: -9px;
  }

  .dia::after {
    content: '';
    position: absolute;
    left: -3px;
    top: 3px;
    width: 0;
    height: 0;
    border: 3px solid transparent;
    border-top-color: #d4a354;
  }
</style>
