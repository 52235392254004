<svg
  width="21"
  height="20"
  viewBox="0 0 21 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M10.5002 6.77416C8.70825 6.77416 7.24584 8.23657 7.24584 10.0286C7.24584 11.8206 8.70825 13.283 10.5002 13.283C12.2922 13.283 13.7546 11.8206 13.7546 10.0286C13.7546 8.23657 12.2922 6.77416 10.5002 6.77416ZM20.261 10.0286C20.261 8.6809 20.2732 7.34545 20.1975 6.00024C20.1218 4.43774 19.7654 3.05102 18.6228 1.90844C17.4778 0.763421 16.0935 0.409418 14.531 0.333734C13.1833 0.25805 11.8479 0.270257 10.5027 0.270257C9.15502 0.270257 7.81958 0.25805 6.47436 0.333734C4.91186 0.409418 3.52514 0.765863 2.38256 1.90844C1.23754 3.05346 0.883539 4.43774 0.807855 6.00024C0.732171 7.34789 0.744379 8.68334 0.744379 10.0286C0.744379 11.3738 0.732171 12.7117 0.807855 14.0569C0.883539 15.6194 1.23998 17.0061 2.38256 18.1487C3.52758 19.2937 4.91186 19.6477 6.47436 19.7234C7.82202 19.7991 9.15747 19.7869 10.5027 19.7869C11.8503 19.7869 13.1858 19.7991 14.531 19.7234C16.0935 19.6477 17.4802 19.2913 18.6228 18.1487C19.7678 17.0037 20.1218 15.6194 20.1975 14.0569C20.2756 12.7117 20.261 11.3762 20.261 10.0286ZM10.5002 15.0359C7.72924 15.0359 5.49291 12.7996 5.49291 10.0286C5.49291 7.25756 7.72924 5.02123 10.5002 5.02123C13.2712 5.02123 15.5076 7.25756 15.5076 10.0286C15.5076 12.7996 13.2712 15.0359 10.5002 15.0359ZM15.7126 5.98559C15.0657 5.98559 14.5432 5.46313 14.5432 4.81616C14.5432 4.16918 15.0657 3.64672 15.7126 3.64672C16.3596 3.64672 16.8821 4.16918 16.8821 4.81616C16.8823 4.96978 16.8522 5.12194 16.7935 5.26391C16.7348 5.40588 16.6486 5.53487 16.54 5.6435C16.4314 5.75213 16.3024 5.83826 16.1604 5.89697C16.0184 5.95567 15.8663 5.98578 15.7126 5.98559Z"
    fill="#D4A354"
  />
</svg>
