<script>
  import SectHeading from '../common/SectHeading.svelte';

  import src from 'url:./save-a-chair.png';
</script>

<section class="relative w-full offset-header" id="BookAppointment">
  <div class="relative w-full overflow-hidden img-wrapper">
    <img
      class="absolute w-auto h-full lg:object-cover image lg:w-full lg:h-auto max-w-none lg:static"
      alt="Barber's scissors and other tools"
      {src}
    />
    <div class="absolute inset-0 opacity-75 bg-darkSlateGray" />
  </div>
  <div
    class="container absolute px-2 py-4 transform -translate-x-1/2 -translate-y-1/2 top-half left-half"
  >
    <SectHeading text="We'll Save You a Chair" light center />
    <a
      href="https://booksy.com/en-us/514900_noah-cutz-and-stylz-suite-2194_barber-shop_36542_lewisville"
    >
      <button
        class="block px-12 py-3 mx-auto font-bold leading-none text-center text-white appearance-none mt-1/4 whitespace-nowrap bg-sandyBrown-500 lg:px-16 lg:text-lg book-appointment lg:mt-1/6"
      >
        Book Appointment
      </button>
    </a>
  </div>
</section>

<style>
  .img-wrapper {
    min-height: 275px;
    height: 50vh;
  }

  .image {
    transform: translateX(-20%);
    min-width: 100vw;
  }

  .book-appointment {
    font-family: var(--pt-sans-18-bold-family);
    border-radius: 12px;
  }

  .book-appointment:hover {
    box-shadow: 0.4rem 0.4rem var(--sandy-brown-darker);
  }

  .book-appointment:active {
    color: var(--sandy-brown-darker);
  }

  @media only screen and (min-width: 1024px) {
    .img-wrapper {
      height: auto;
    }

    .image {
      min-height: 500px;
      transform: none;
    }
  }
</style>
