<script>
  export let href;
  export let content;
  export let color = 'white';
  export let hovColor = 'sandyBrown-500';
  export let handleClick = () => {};
</script>

<a
  class={`hidden lg:flex items-center h-full px-4 font-normal text-${color} hover:text-${hovColor}`}
  {href}
>
  {content}
</a>
<!-- Mobile NavLink -->
<a
  class={`lg:hidden text-center w-full p-6 border-b border-dimmerGray font-normal text-${color} text-xl hover:text-${hovColor} last:border-0`}
  {href}
  on:click={handleClick}
>
  {content}
</a>

<style>
</style>
