<script>
  import SectHeading from '../common/SectHeading.svelte';

  import src1 from 'url:./img-1.png';
  import src2 from 'url:./img-2.png';
</script>

<div class="offset-header" id="AboutUs" />
<section
  class="container flex flex-col items-start px-8 pt-8 pb-16 story lg:flex-row lg:py-16"
>
  <section class="mb-12 lg:mb-0 lg:order-2 lg:w-1/2">
    <SectHeading text="Our Story" />
    <h3 class="mb-8 font-bold lg:text-xl">BARBER & Motivational speaker</h3>
    <p class="pb-1 text-sm lg:text-lg lg:pb-4">The Noah story coming soon.</p>
    <div class="text-right">
      <div class="pb-2 text-4xl italic noah-floyd lg:text-5xl">-Noah Floyd</div>
      <div class="text-sm barber-ceo lg:text-lg">Barber &amp; CEO</div>
    </div>
  </section>
  <section class="relative w-full lg:order-1 lg:mr-12 lg:w-1/2 xl:mr-20">
    <div class="relative z-10 w-1/2 px-2 overflow-hidden shadow-lg lg:px-4">
      <img
        class="block w-full h-auto"
        alt="Noah brushing a client's hair."
        src={src1}
        width="243"
        height="364"
      />
      <div class="absolute inset-0 border-white border-10 lg:border-16" />
    </div>
    <div
      class="absolute z-0 w-2/3 overflow-hidden shadow-lg floating-image -right-0 lg:right-auto lg:left-1/3"
    >
      <img
        class="block w-full h-auto"
        alt="2 barbers at work cutting men's hair."
        src={src2}
        width="342"
        height="228"
      />
      <div class="absolute inset-0 border-white border-10 lg:border-16" />
    </div>
  </section>
</section>

<style>
  .floating-image {
    bottom: -12%;
  }
  .noah-floyd {
    color: var(--dim-gray);
    font-family: var(--brush-script-mt-45-regular-family);
    font-size: var(--brush-script-mt-45-regular-size);
    font-weight: var(--brush-script-mt-45-regular-weight);
    line-height: var(--brush-script-mt-45-regular-line-height);
  }
  /*
  .flex-wrapper-two {
    box-shadow: 0 0 10px 6px var(--transparent-black);
  }
  .flex-wrapper-three {
    box-shadow: 0 0 10px 6px var(--transparent-black);
  }
  .subheader-text {
    height: 84px;
    width: 558px;
    color: var(--dim-gray);
    display: flex;
    font-family: var(--open-sans-20-bold-family);
    font-size: var(--open-sans-20-bold-size);
    font-weight: var(--open-sans-20-bold-weight);
    line-height: var(--open-sans-20-bold-line-height);
  }
  .noah-floyd {
    color: var(--dim-gray);
    font-family: var(--brush-script-mt-45-regular-family);
    font-size: var(--brush-script-mt-45-regular-size);
    font-weight: var(--brush-script-mt-45-regular-weight);
    line-height: var(--brush-script-mt-45-regular-line-height);
  }
  .barber-ceo {
    color: var(--dim-gray);
    font-family: var(--open-sans-18-regular-family);
    font-size: var(--open-sans-18-regular-size);
    font-weight: var(--open-sans-18-regular-weight);
    line-height: var(--open-sans-18-regular-line-height);
  } */
</style>
