<script>
  import SectHeading from '../common/SectHeading.svelte';
  import clock from 'url:./clock.png';
  // import envelope from 'url:./envelope.png';
  import mapPoint from 'url:./map-point.png';
  import phone from 'url:./phone.png';

  import ContactItem from './ContactItem.svelte';
</script>

<section class="contact-us offset-header bg-whiteSmoke" id="ContactUs">
  <div class="container pt-8 lg:pt-16">
    <SectHeading text="Contact Us" center />
  </div>
  <section class="map">
    <iframe
      class="map"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13384.224977808672!2d-96.9717506!3d33.0022926!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x78309cdd3dd9ddeb!2sMusic%20City%20Mall%20Lewisville!5e0!3m2!1sen!2sus!4v1617504161427!5m2!1sen!2sus"
      width="100%"
      height="450"
      style="border:0;"
      allowfullscreen=""
      loading="lazy"
      title="Map with location of Noah's Cutz & Styles marked"
    />
  </section>
  <section
    class="container flex flex-wrap justify-between px-8 pb-8 mt-12 lg:px-12 lg:flex-nowrap lg:mt-20 lg:pb-4"
  >
    <ContactItem icon={mapPoint} iconAlt="Address" heading="Location">
      <a
        class="text-sandyBrown-700 hover:underline"
        href="https://g.page/MusicCityMallLewisville?share"
        title="Go to location in Google Maps"
      >
        <address class="not-italic">
          Music City Mall<br />
          2401 S. Stemmons Fwy<br />
          Suite 2194<br />
          Lewisville, TX 75067
        </address>
      </a>
    </ContactItem>
    <ContactItem icon={clock} iconAlt="Buniness Hours" heading="Hours">
      <div>M-F: 10am - 9pm</div>
      <div>Sa: 10am - 9pm</div>
      <div>Su: 12am - 6pm</div>
    </ContactItem>
    <!--    <ContactItem-->
    <!--      icon={envelope}-->
    <!--      iconAlt="Contact Email Address"-->
    <!--      heading="Email"-->
    <!--    >-->
    <!--      <a-->
    <!--        class="text-sandyBrown-700 hover:underline"-->
    <!--        href="mailto:webmaster@example.com"-->
    <!--        >webmaster@example.com-->
    <!--      </a>-->
    <!--    </ContactItem>-->
    <ContactItem icon={phone} iconAlt="Contact Phone Number" heading="Phone">
      <a class="text-sandyBrown-700 hover:underline" href="tel:4692400827"
        >(469) 240-0827</a
      >
    </ContactItem>
  </section>
</section>

<style>
  .map {
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: center;
  }
</style>
