<script>
  export let className = 'w-8 h-5';
  export let strokeWidth = '1';
</script>

<svg
  class={className}
  viewBox="0 0 30 18"
  fill="none"
  stroke="currentColor"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M29.1803 17.1006C28.0526 18.2541 26.4827 18.3446 25.1042 17.1006L14.9969 7.4097L4.88964 17.1006C3.51114 18.3446 1.93866 18.2541 0.818792 17.1006C-0.308839 15.9497 -0.236422 14.0048 0.818792 12.9237C1.86883 11.8426 12.9615 1.28015 12.9615 1.28015C13.2257 1.00638 13.5423 0.788635 13.8924 0.639916C14.2426 0.491197 14.6191 0.414551 14.9995 0.414551C15.38 0.414551 15.7565 0.491197 16.1066 0.639916C16.4568 0.788635 16.7734 1.00638 17.0375 1.28015C17.0375 1.28015 28.125 11.8426 29.1803 12.9237C30.2381 14.0048 30.3079 15.9497 29.1803 17.1006Z"
    fill="currentColor"
    {strokeWidth}
  />
</svg>
