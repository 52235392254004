<svg
  width="10"
  height="20"
  viewBox="0 0 10 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M2.55301 20V10.6154H0.000244141V7.23652H2.55301V4.3505C2.55301 2.08264 4.06094 0 7.53554 0C8.94234 0 9.98261 0.1311 9.98261 0.1311L9.90064 3.28642C9.90064 3.28642 8.83974 3.27638 7.68203 3.27638C6.42903 3.27638 6.22829 3.83768 6.22829 4.7693V7.23652H10.0002L9.83612 10.6154H6.22829V20H2.55301Z"
    fill="#D4A354"
  />
</svg>
