<script>
  import NavLink from './NavLink.svelte';

  export let sections;
  export let setOpen = () => {};
  export let activeSection = sections[0];
  export let setActiveSection = () => {};
</script>

<div class="relative hidden mb-12 lg:block">
  <nav class="flex justify-between" role="navigation">
    {#each sections as sect}
      <NavLink
        active={activeSection === sect}
        content={sect.label}
        handleClick={() => {
          setActiveSection(sect);
          setOpen(false);
        }}
      />
    {/each}
  </nav>
</div>
