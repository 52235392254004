<script>
  import DecorativeLine from './DecorativeLine.svelte';

  export let text;
  export let center = false;
  export let light = false;
</script>

<header class={`mb-8 ${center ? 'text-center' : ''}`}>
  <div class="inline-flex flex-col">
    <h2
      class={`mb-3 text-4xl ${light ? 'text-white' : ''} lg:text-5xl lg:mb-4`}
    >
      {text}
    </h2>
    <DecorativeLine />
  </div>
</header>
