<script>
  import AngleDown from '../common/AngleDown.svelte';
  import src from 'url:./banner-desktop.png';
</script>

<section class="relative w-full hero-section" id="Top">
  <div class="w-full overflow-hidden img-wrapper">
    <img
      class="object-cover w-auto h-2/3vh hero-wrapper"
      alt="Noah trimming a man's hair."
      title="Banner Image"
      {src}
    />
    <div class="absolute inset-0 opacity-75 bg-darkSlateGray" />
  </div>
  <h1
    class="absolute block w-full px-4 text-4xl leading-normal text-center text-white sm:px-0 sm:w-4/5 md:text-5xl lg:leading-relaxed lg:w-3/5 lg:text-6xl xl:text-65xl title"
  >
    The Official Barber <br/> of the Dallas Cowboys
  </h1>
  <div class="absolute flex flex-col items-center justify-center buttons">
    <a
      class="block px-12 py-3 font-bold leading-none text-center text-white whitespace-nowrap bg-sandyBrown-500 lg:px-16 lg:text-lg book-appointment"
      href="https://booksy.com/en-us/514900_noah-cutz-and-stylz-suite-2194_barber-shop_36542_lewisville"
      >Book Appointment</a
    >
    <a
      class="block p-4 text-sm font-normal text-center my-1/20 text-sandyBrown-500 hover:text-sandyBrown-700 sm:mt-1/10 lg:text-normal see-more"
      href="#AboutUs"
      title="See more"
    >
      <AngleDown />
    </a>
  </div>
</section>

<style>
  /* only do this when the device width is greater than height */
  @media (min-aspect-ratio: 11/10) {
    .hero-section {
      height: 100vh;
    }

    .hero-wrapper {
      position: absolute;
      height: 100vh;
      width: auto;
    }
  }

  .title {
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .buttons {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .book-appointment {
    font-family: var(--pt-sans-18-bold-family);
    border-radius: 12px;
  }

  .book-appointment:hover {
    box-shadow: 0.4rem 0.4rem var(--sandy-brown-darker);
  }

  .book-appointment:active {
    color: var(--sandy-brown-darker);
  }
</style>
