<script>
  import AngleUp from '../common/AngleUp.svelte';
  import Logo from '../common/Logo.svelte';
  import Facebook from '../common/Facebook.svelte';
  import Instagram from '../common/Instagram.svelte';
  // import Twitter from '../common/Twitter.svelte';
</script>

<footer class="relative pt-12 pb-8 text-white footer bg-darkSlateGray">
  <a
    href="#Top"
    class="absolute top-0 inline-flex justify-center w-16 h-16 pt-4 transform -translate-x-1/2 -translate-y-1/2 rounded-full text-sandyBrown-500 left-half bg-darkSlateGray"
  >
    <AngleUp />
  </a>
  <div class="container flex flex-wrap">
    <div
      class="flex justify-center w-full mb-12 lg:mb-0 lg:w-1/2 lg:justify-start"
    >
      <Logo />
    </div>
    <div
      class="flex justify-center w-full mb-20 lg:w-1/2 lg:mb-0 lg:justify-end"
    >
      <div class="flex justify-center">
        <a
          href="https://www.facebook.com/Noah-Cutz-and-Stylz-332539927274175"
          title="Connect on Facebook"
        >
          <Facebook />
        </a>
      </div>
      <div class="flex justify-center mx-6">
        <a
          href="https://www.instagram.com/noahcutzandstylz/"
          title="Connect on Instagram"
        >
          <Instagram />
        </a>
      </div>
      <!--      <div class="flex justify-center"><Twitter /></div>-->
    </div>
    <p class="w-full text-xs text-center lg:text-sm lg:mt-10">
      © 2021 Noah’s Cuts &amp; Styles All Rights Reserved
    </p>
  </div>
</footer>

<style>
</style>
