<script>
  import Hero from './Hero/HeroSection.svelte';
  import About from './About/AboutSection.svelte';
  import Services from './Service/ServiceSection.svelte';
  import Booking from './Booking/BookingSection.svelte';
  import Gallery from './Gallery/GallerySection.svelte';
  // import Testimonial from './Testimonial/TestimonialSection.svelte';
  import Contact from './Contact/ContactSection.svelte';
  import Footer from './Footer/FooterSection.svelte';
  import NavBar from './Hero/NavBar.svelte';
</script>

<div class="flex flex-col justify-between h-screen">
  <NavBar />
  <main>
    <Hero />
    <About />
    <Services />
    <Booking />
    <Gallery />
    <!-- <Testimonial /> -->
    <Contact />
  </main>
  <Footer />
</div>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Oswald&PT+Sans:wght@700&display=swap');
</style>
