<script>
  import Logo from '../common/Logo.svelte';
  import NavLink from './NavLink.svelte';
  import Hamburger from './Hamburger.svelte';

  let isMenuOpen = false;
  const handleClick = () => {
    isMenuOpen = false;
  };
</script>

<header
  class="fixed z-50 flex justify-center w-full bg-transparentDarkSlateGray"
>
  <!-- tricking purge css -->
  <div class="text-sandyBrown-700 hover:text-sandyBrown-500" />
  <!-- end trick -->
  <div class="container hidden w-full p-4 lg:flex">
    <h2 class="mr-12"><Logo /></h2>
    <nav class="flex justify-end flex-grow our-link" role="navigation">
      <NavLink href="#AboutUs" content="Our Story" />
      <NavLink href="#Services" content="Services" />
      <NavLink href="#Gallery" content="Gallery" />
      <!-- <NavLink href="#Testimonials" content="Testimonials" /> -->
      <NavLink href="#ContactUs" content="Contact us" />
      <NavLink
        href="https://booksy.com/en-us/514900_noah-cutz-and-stylz-suite-2194_barber-shop_36542_lewisville"
        content="Book Appointment"
        color="sandyBrown-700"
        hovColor="sandyBrown-500"
      />
    </nav>
  </div>
  <!-- mobile header -->
  <div class="relative flex justify-between w-full p-4 lg:hidden">
    <h2><Logo /></h2>
    <button on:click={() => (isMenuOpen = !isMenuOpen)} title="Open Menu">
      <Hamburger isOpen={isMenuOpen} />
    </button>
    {#if isMenuOpen}
      <nav
        class="absolute left-0 right-0 flex flex-col px-12 py-4 bg-darkSlateGray top-full"
        role="navigation"
      >
        <NavLink href="#AboutUs" content="Our Story" {handleClick} />
        <NavLink href="#Services" content="Services" {handleClick} />
        <NavLink href="#Gallery" content="Gallery" {handleClick} />
        <NavLink href="#Testimonials" content="Testimonials" {handleClick} />
        <NavLink href="#ContactUs" content="Contact us" {handleClick} />
        <NavLink
          href="https://booksy.com/en-us/514900_noah-cutz-and-stylz-suite-2194_barber-shop_36542_lewisville"
          content="Book Appointment"
          color="sandyBrown-700"
          hovColor="sandyBrown-500"
          {handleClick}
        />
      </nav>
    {/if}
  </div>
</header>

<style>
</style>
