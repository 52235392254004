<script>
  export let className = 'w-8 h-5';
  export let strokeWidth = '1';
</script>

<svg
  class={className}
  viewBox="0 0 35 21"
  fill="none"
  stroke="currentColor"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M0.95637 1.07402C2.27194 -0.303448 4.10348 -0.411545 5.71173 1.07402L17.5036 12.6466L29.2954 1.07402C30.9037 -0.411545 32.7382 -0.303448 34.0447 1.07402C35.3603 2.4484 35.2758 4.77095 34.0447 6.06194C32.8197 7.35293 19.8782 19.9663 19.8782 19.9663C19.5701 20.2933 19.2007 20.5533 18.7922 20.7309C18.3837 20.9085 17.9444 21 17.5006 21C17.0567 21 16.6174 20.9085 16.2089 20.7309C15.8004 20.5533 15.4311 20.2933 15.1229 19.9663C15.1229 19.9663 2.18745 7.35293 0.95637 6.06194C-0.27773 4.77095 -0.359199 2.4484 0.95637 1.07402Z"
    fill="currentColor"
    {strokeWidth}
  />
</svg>
