<script>
  import SectHeading from '../common/SectHeading.svelte';

  import Box from './Box.svelte';

  import brush from 'url:./icons/brush.png';
  import hairLong from 'url:./icons/hair-long.png';
  import hairShort from 'url:./icons/hair-short.png';
  import lashes from 'url:./icons/lashes.png';
  import razor from 'url:./icons/razor.png';
  import scissors from 'url:./icons/scissors.png';
  import trimmer from 'url:./icons/trimmer.png';
</script>

<section class="offset-header bg-whiteSmoke" id="Services">
  <div
    class="container items-start px-8 pt-8 pb-16 sect-container lg:pt-16 lg:pb-28 lg:px-12"
  >
    <SectHeading text="Our Services" center />
    <div class="lg:mt-12" />
    <section
      class="flex flex-wrap justify-center -m-4 xl:-m-8 md:justify-start"
    >
      <Box title="Haircut &amp; Fade" alt="Scissors" src={scissors} />
      <Box title="Fades" alt="Trimmer" src={trimmer} />
      <Box title="Edge Up &amp; Beard" src={razor} alt="Straight Razor" />
      <Box
        title="Braids &amp; Dreads"
        src={hairLong}
        alt="Icon with Dreadlocks"
      />
      <Box title="Kid's Haircut" alt="Short Hair" src={hairShort} />
      <Box
        title="Brow Shaping &amp; Eye Lashes"
        alt="Eye Brow and Eye Lashes"
        src={lashes}
      />
      <Box title="Bigen &amp; Hair Dyes" src={brush} alt="Brush and Dye" />
      <Box title="Additional Services">
        <div
          class="relative w-full mt-4 text-sm text-center bg-white lg:text-base"
        >
          <ul class="list-disc list-inside">
            <li class="text-left">Wigs</li>
            <li class="text-left">Nails</li>
            <li class="text-left">Body Sculpting</li>
            <li class="text-left">Micro-Blading</li>
            <li class="text-left">Facials</li>
          </ul>
        </div>
      </Box>
    </section>
  </div>
</section>

<style>
</style>
