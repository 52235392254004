<script>
  export let icon;
  export let iconAlt;
  export let heading;
</script>

<section
  class="flex flex-col items-center w-full mb-16 sm:w-1/2 sm:px-2 sm:pb-12 sm:mb-0 lg:w-1/4"
>
  <img alt={iconAlt} src={icon} />
  <heading class="mt-4 lg:mt-6">
    <h3 class="font-bold">
      {heading}
    </h3>
  </heading>
  <section class="mt-4">
    <div class="text-center">
      <slot />
    </div>
  </section>
</section>

<style>
</style>
