<svg
  width="85"
  height="11"
  viewBox="0 0 85 11"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M0.5 5.43198L5.18198 0.75L9.86395 5.43198L5.18198 10.114L0.5 5.43198Z"
    fill="#D4A354"
  />
  <path
    d="M12.75 5.43198L17.432 0.75L22.114 5.43198L17.432 10.114L12.75 5.43198Z"
    fill="#D4A354"
  />
  <path
    d="M24.9999 5.43198L29.6819 0.75L34.3639 5.43198L29.6819 10.114L24.9999 5.43198Z"
    fill="#D4A354"
  />
  <path d="M29.6819 4.74997H85V6.99997H29.6819V4.74997Z" fill="#D4A354" />
</svg>
