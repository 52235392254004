<script>
  export let title;
  export let src;
  export let alt;
</script>

<section
  class="relative flex flex-col items-center flex-grow w-1/2 p-4 box lg:w-1/4"
>
  <div class="relative w-full box-content-container">
    <div
      class="absolute z-0 top-2 -right-2 -bottom-2 left-2 bg-gainsboro xl:top-3 xl:-right-3 xl:-bottom-3 xl:left-3 background"
    />
    <div class="absolute inset-0 bg-white" />
    <div class="absolute inset-0 flex flex-col p-4">
      {#if !!src}
        <div class="relative flex items-center w-full img-wrapper">
          <img class="block w-auto h-auto mx-auto" {alt} {src} />
        </div>
      {/if}
      <h3 class="relative w-full mt-4 font-bold text-center title lg:text-lg">
        {title}
      </h3>
      <slot />
    </div>
  </div>
</section>

<style>
  .box {
    min-width: 200px;
    max-width: 245px;
  }

  .box-content-container:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  .img-wrapper {
    height: 108px;
  }

  @media only screen and (min-width: 1024px) {
    .box {
      max-width: none;
    }
    .img-wrapper ~ .title {
      min-height: 56px;
    }
  }

  @media only screen and (min-width: 1280px) {
    .box {
      padding: 4%;
    }
  }
</style>
